import React, { useState, useEffect, useContext } from 'react';
import { supabase } from '../../supabaseClient';
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from 'react-i18next';
import './MyProfile.css';

const MyProfile = () => {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const { t } = useTranslation('MyProfile');
    const [profile, setProfile] = useState({
        username: '',
        full_name: '',
        avatar_url: '',
        website: '',
        bio: '',
        profile_title: '',
    });
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const fetchProfile = async () => {
            const { data, error } = await supabase
                .from('profiles')
                .select('*')
                .eq('id', user.id)
                .single();

            if (error) {
                console.error('Error fetching profile:', error);
                setErrorMessage(t('fetchError'));
            } else {
                setProfile(data);
                setErrorMessage('');
            }
        };

        if (user) {
            fetchProfile();
        }
    }, [user, t]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfile((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { error } = await supabase
            .from('profiles')
            .update({
                website: profile.website,
                bio: profile.bio.slice(0, 300),
                profile_title: profile.profile_title.slice(0, 30),
            })
            .eq('id', user.id);

        if (error) {
            console.error('Error updating profile:', error);
            setErrorMessage(t('updateError'));
        } else {
            setSuccessMessage(t('updateSuccess'));
            setErrorMessage('');
            setTimeout(() => setSuccessMessage(''), 4000);
        }
    };

    const viewProfile = () => {
        navigate(`/hero/${profile.username}`);
    };

    return (
        <div className="my-profile-container">
            <h1>{t('editProfileTitle')}</h1>
            {successMessage && <div className="success-message">{successMessage}</div>}
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="username">{t('username')}</label>
                    <input type="text" id="username" name="username" value={profile.username} disabled />
                </div>
                <div className="form-group">
                    <label htmlFor="full_name">{t('name')}</label>
                    <input type="text" id="full_name" name="full_name" value={profile.full_name} disabled />
                </div>
                <div className="form-group">
                    <label htmlFor="profile_title">{t('profileTitle')}</label>
                    <input
                        type="text"
                        id="profile_title"
                        name="profile_title"
                        value={profile.profile_title}
                        onChange={handleChange}
                        maxLength={30}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="website">{t('website')}</label>
                    <input type="url" id="website" name="website" value={profile.website} onChange={handleChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="bio">{t('bio')}</label>
                    <textarea
                        id="bio"
                        name="bio"
                        value={profile.bio}
                        onChange={handleChange}
                        rows={4}
                        maxLength={300}
                    ></textarea>
                </div>
                <button type="submit" className="save-button">
                    <SaveIcon style={{ marginRight: '8px' }} />
                    {t('saveChanges')}
                </button>
            </form>
            <button className="view-profile-button" onClick={viewProfile}>
                <VisibilityIcon style={{ marginRight: '8px' }} />
                {t('viewProfile')}
            </button>
        </div>
    );
};

export default MyProfile;
