import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import LocationSearch from './LocationSearch';

const LocationSelector = ({ onChange, isGlobal, setIsGlobal, initialLocations = [] }) => {
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [currentSelection, setCurrentSelection] = useState({
    country: null,
    region: null,
    city: null,
  });
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [previousLocations, setPreviousLocations] = useState(initialLocations);

  useEffect(() => {
    // Fetch countries on component mount
    const fetchCountries = async () => {
      const { data, error } = await supabase.from('countries').select('*');
      if (error) {
        console.error('Error fetching countries:', error);
      } else {
        setCountries(data);
      }
    };
    fetchCountries();
  }, []);

  const fetchRegions = async (countryId) => {
    const { data, error } = await supabase
      .from('regions')
      .select('*')
      .eq('country_id', countryId);
    if (error) {
      console.error('Error fetching regions:', error);
    } else {
      setRegions(data);
      setCities([]); // Reset cities when a new country is selected
    }
  };

  const fetchCities = async (regionId) => {
    const { data, error } = await supabase
      .from('cities')
      .select('*')
      .eq('region_id', regionId);
    if (error) {
      console.error('Error fetching cities:', error);
    } else {
      setCities(data);
    }
  };

  const handleCountryChange = (e) => {
    const countryId = e.target.value;
    setCurrentSelection((prev) => ({
      ...prev,
      country: countryId,
      region: null,
      city: null,
    }));
    if (countryId) {
      fetchRegions(countryId);
    } else {
      setRegions([]);
      setCities([]);
    }
  };

  const handleRegionChange = (e) => {
    const regionId = e.target.value;
    setCurrentSelection((prev) => ({
      ...prev,
      region: regionId,
      city: null,
    }));
    if (regionId) {
      fetchCities(regionId);
    } else {
      setCities([]);
    }
  };

  const handleCityChange = (e) => {
    const cityId = e.target.value;
    setCurrentSelection((prev) => ({
      ...prev,
      city: cityId,
    }));
  };

  const handleAddLocation = async () => {
    const { country, region, city } = currentSelection;

    // If no country is selected, do nothing
    if (!country) {
      console.log("No country selected. Cannot add location.");
      return;
    }

    let newLocation = {};
    if (city) {
      const { data: cityData } = await supabase
        .from('cities')
        .select('id, name, region_id, regions(id, name, country_id, countries(id, name))')
        .eq('id', city)
        .single();

      newLocation = {
        country: cityData.regions.countries.id,
        countryName: cityData.regions.countries.name,
        region: cityData.regions.id,
        regionName: cityData.regions.name,
        city: cityData.id,
        cityName: cityData.name,
      };
    } else if (region) {
      const { data: regionData } = await supabase
        .from('regions')
        .select('id, name, country_id, countries(id, name)')
        .eq('id', region)
        .single();

      newLocation = {
        country: regionData.countries.id,
        countryName: regionData.countries.name,
        region: regionData.id,
        regionName: regionData.name,
      };
    } else if (country) {
      const { data: countryData } = await supabase
        .from('countries')
        .select('id, name')
        .eq('id', country)
        .single();

      newLocation = {
        country: countryData.id,
        countryName: countryData.name,
      };
    }

    // Check if the location already exists in selectedLocations
    const isDuplicate = selectedLocations.some(
      location =>
        location.country === newLocation.country &&
        (location.region === newLocation.region || !newLocation.region) &&
        (location.city === newLocation.city || !newLocation.city)
    );

    // If it's a duplicate, do nothing
    if (isDuplicate) {
      console.log("Location already exists. Cannot add duplicate.");
      return;
    }

    // First, update the state
    setSelectedLocations((prev) => {
      const updatedLocations = [...prev, newLocation];
      return updatedLocations; // Only update state here
    });

    // Then, after state has been updated, trigger onChange
    onChange([...selectedLocations, newLocation]);

    // Reset current selection
    setCurrentSelection({ country: null, region: null, city: null });

    console.log("selectedLocations", selectedLocations);
  };


  const handleRemoveLocation = (index) => {
    setSelectedLocations((prev) => {
      const updatedLocations = prev.filter((_, i) => i !== index);
      onChange(updatedLocations);
      return updatedLocations;
    });
  };

  const toggleGlobal = () => {
    setIsGlobal((prev) => {
      const newGlobalState = !prev;
      if (newGlobalState) {
        setPreviousLocations(selectedLocations);
        setSelectedLocations([]);
        onChange([]);
      } else {
        setSelectedLocations(previousLocations);
        onChange(previousLocations);
      }
      return newGlobalState;
    });
  };

  const handleSearchSelect = async (location) => {
    let newSelection = {};
  
    console.log("got location", location);
  
    switch (location.type) {
      case 'country':
        newSelection = {
          country: location.id,
          countryName: location.countryName,
          region: null,
          regionName: null,
          city: null,
          cityName: null
        };
  
        // Simulate the behavior as if the user selected the country via the dropdown
        setCurrentSelection(newSelection);
        handleCountryChange({ target: { value: location.id } }); // Trigger the loading of regions
        break;
  
      case 'region':
        newSelection = {
          country: location.countryId,  // Ensure that the country ID is passed from LocationSearch
          countryName: location.countryName,
          region: location.id,
          regionName: location.regionName,
          city: null,
          cityName: null
        };
  
        // Set the selection and trigger loading of cities
        setCurrentSelection(newSelection);
        handleCountryChange({ target: { value: location.countryId } });  // Ensure regions are fetched
        handleRegionChange({ target: { value: location.id } });  // Trigger the loading of cities
        break;
  
      case 'city':
        newSelection = {
          country: location.countryId,  // Ensure that the country ID is passed from LocationSearch
          countryName: location.countryName,
          region: location.regionId,   // Ensure that the region ID is passed from LocationSearch
          regionName: location.regionName,
          city: location.id,
          cityName: location.cityName
        };
  
        // Set the selection and ensure regions and cities are fetched
        setCurrentSelection(newSelection);
        handleCountryChange({ target: { value: location.countryId } });  // Ensure regions are fetched
        handleRegionChange({ target: { value: location.regionId } });  // Ensure cities are fetched
        break;
  
      default:
        return;
    }
  
    // Check if the location already exists in selectedLocations
    const isDuplicate = selectedLocations.some(
      loc =>
        loc.country === newSelection.country &&
        (loc.region === newSelection.region || !newSelection.region) &&
        (loc.city === newSelection.city || !newSelection.city)
    );
  
    if (isDuplicate) {
      console.log("Location already exists. Cannot add duplicate.");
      return;
    }
  
    // Add the new location to selectedLocations
    setSelectedLocations((prev) => {
      const updatedLocations = [...prev, newSelection];
      onChange(updatedLocations);
      return updatedLocations;
    });
  };
  

  return (
    <div className="location-selector">
      <label>
        <input
          type="checkbox"
          checked={isGlobal}
          onChange={toggleGlobal}
        />
        Global
      </label>

      {!isGlobal && (
        <div>
          <LocationSearch onSelect={handleSearchSelect} />
          <select onChange={handleCountryChange} value={currentSelection.country || ''}>
            <option value="">Select Country</option>
            {countries.map((country) => (
              <option key={country.id} value={country.id}>
                {country.name}
              </option>
            ))}
          </select>

          <select onChange={handleRegionChange} value={currentSelection.region || ''} disabled={!currentSelection.country}>
            <option value="">Select Region</option>
            {regions.map((region) => (
              <option key={region.id} value={region.id}>
                {region.name}
              </option>
            ))}
          </select>

          <select onChange={handleCityChange} value={currentSelection.city || ''} disabled={!currentSelection.region}>
            <option value="">Select City</option>
            {cities.map((city) => (
              <option key={city.id} value={city.id}>
                {city.name}
              </option>
            ))}
          </select>

          <button type="button" onClick={handleAddLocation}>
            Add Location
          </button>

          <ul>
            {selectedLocations.map((location, index) => (
              <li key={index}>
                {location.countryName ? location.countryName : 'Unknown Country'}
                {location.regionName ? ` > ${location.regionName}` : ''}
                {location.cityName ? ` > ${location.cityName}` : ''}
                <button type="button" onClick={() => handleRemoveLocation(index)}>
                  Remove
                </button>
              </li>
            ))}
          </ul>

        </div>
      )}
    </div>
  );
};

export default LocationSelector;
