import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { TextField, Button, Typography, Box } from '@mui/material';
import { AuthContext } from '../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { acceptedCategory } from 'vanilla-cookieconsent';
import './SignUpLogin.css';

const SignUpLogin = ({ isSignUp: initialIsSignUp, redirectPath }) => {
  const { t } = useTranslation(['SignUpLogin', 'Common']);

  const [isSignUp, setIsSignUp] = useState(initialIsSignUp);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [isGoogleSignInLoaded, setIsGoogleSignInLoaded] = useState(false);
  const navigate = useNavigate();
  const { user, setUser } = useContext(AuthContext);

  useEffect(() => {
    const initializeGoogleSign = () => {
      const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
      if (window.google) {
        window.google.accounts.id.initialize({
          client_id: clientId,
          callback: handleGoogleCallback,
        });
        window.google.accounts.id.renderButton(
          document.getElementById('googleSignInButton'),
          { theme: 'outline', size: 'large' }
        );
        setIsGoogleSignInLoaded(true);
      }
    };

    const handleConsentChange = () => {
      const marketingConsent = acceptedCategory('marketing');
      if (marketingConsent && window.google && document.getElementById('google-client-script')) {
        initializeGoogleSign();
      } else {
        console.warn('Google Sign-In script is not loaded due to lack of consent or script not present');
        setIsGoogleSignInLoaded(false);
      }
    };

    handleConsentChange();

    const consentListener = () => handleConsentChange();
    document.addEventListener('CookieConsentChange', consentListener);

    return () => {
      document.removeEventListener('CookieConsentChange', consentListener);
    };
  }, []);

  const handleGoogleCallback = async (response) => {
    console.log('Google callback response:', response);
    if (!response || !response.credential) {
      setMessage(t('googleAuthFailed'));
      return;
    }

    try {
      const { data, error } = await supabase.auth.signInWithIdToken({
        token: response.credential,
        provider: 'google',
      });

      if (error) {
        console.error('Supabase authentication error:', error);
        setMessage(error.message);
      } else {
        setUser(data.user); // Update user context
        setMessage(t('signInSuccessful'));
        navigate(redirectPath === '/login' ? '/' : redirectPath || '/');
      }
    } catch (error) {
      console.error('Unexpected error:', error);
      setMessage(t('unexpectedError'));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSignUp) {
      if (password !== passwordConfirm) {
        setMessage(t('passwordsDoNotMatch'));
        return;
      }

      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            firstName,
            lastName,
          },
        },
      });

      if (error) {
        setMessage(error.message);
      } else {
        setUser(data.user); // Update user context
        setMessage(t('signUpSuccessful'));
        navigate('/verify-email');
      }
    } else {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        setMessage(error.message);
      } else {
        setUser(data.user); // Update user context
        setMessage(t('signInSuccessful'));
        navigate(redirectPath === '/login' ? '/' : redirectPath || '/');
      }
    }
  };

  return (
    <div className="backgroundContainer-signup">
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <Typography variant="h4">{isSignUp ? t('Common:signUp') : t('Common:signIn')}</Typography>
          <TextField
            label={t('Common:email')}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            fullWidth
            margin="normal"
          />
          {isSignUp && (
            <>
              <TextField
                label={t('firstname')}
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                fullWidth
                margin="normal"
              />
              <TextField
                label={t('lastname')}
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                fullWidth
                margin="normal"
              />
            </>
          )}
          <div className="password-container">
            <TextField
              label={t('Common:password')}
              type={passwordVisible ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              fullWidth
              margin="normal"
            />
            <i id={passwordVisible ? 'hide' : 'show'} onClick={() => setPasswordVisible(!passwordVisible)}></i>
          </div>
          {isSignUp && (
            <div className="password-container">
              <TextField
                label={t('Common:confirmPassword')}
                type={confirmPasswordVisible ? 'text' : 'password'}
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
                required
                fullWidth
                margin="normal"
              />
              <i id={confirmPasswordVisible ? 'hide' : 'show'} onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}></i>
            </div>
          )}
          <Typography className="message">{message}</Typography>
          <Button className="orange-button" variant="contained" type="submit" color="primary">
            {isSignUp ? t('Common:signUp') : t('Common:signIn')}
          </Button>
        </form>
        <Box mt={2}>
          <div id="googleSignInButton"></div>
        </Box>
        {!isGoogleSignInLoaded && (
          <Typography variant="body2" color="textSecondary">
            {t('cookieAccept')}
          </Typography>
        )}
        {isSignUp
          ? <Typography className="margin-top">{t('alreadyHaveAnAccount')} <Link className="lightlink" to="#" onClick={() => setIsSignUp(false)}>{t('Common:signIn')}</Link></Typography>
          : <Typography className="margin-top">{t('dontHaveAnAccount')} <Link className="lightlink" to="#" onClick={() => setIsSignUp(true)}>{t('Common:signUp')}</Link></Typography>
        }
      </div>
    </div>
  );
};

export default SignUpLogin;
