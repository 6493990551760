import React from 'react';

const MyOrders = () => {
  return (
    <div>
      <h1>My Orders</h1>
      <p>This is the My Orders page.</p>
    </div>
  );
};

export default MyOrders;
