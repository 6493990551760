import React from 'react';
import { useTranslation } from 'react-i18next';
import './TermsOfService.css';

const TermsOfService = () => {
  const { t } = useTranslation('TermsOfService');

  return (
    <div className="terms-service-container">
      <h1>{t('title')}</h1>
      <p>{t('introduction')}</p>
      <p>
        {t('contact')} <a href={`mailto:${t('contactEmail')}`}>{t('contactEmail')}</a>.
      </p>

      <h2>{t('acceptanceTitle')}</h2>
      <p>{t('acceptanceText')}</p>

      <h2>{t('dataUsageTitle')}</h2>
      <p>{t('dataUsageText')}</p>

      <h2>{t('responsibilityTitle')}</h2>
      <p>{t('responsibilityText')}</p>
      <p>{t('prohibitedContent')}</p>

      <h2>{t('prohibitedActivitiesTitle')}</h2>
      <p>{t('prohibitedActivitiesText')}</p>
      <ul>
        {t('activitiesList', { returnObjects: true }).map((activity, index) => (
          <li key={index}>{activity}</li>
        ))}
      </ul>

      <h2>{t('liabilityTitle')}</h2>
      <p>{t('liabilityText')}</p>

      <h2>{t('removalTitle')}</h2>
      <p>{t('removalText')}</p>

      <h2>{t('chatTitle')}</h2>
      <p>{t('chatText')}</p>
      <ul>
        {t('chatProhibited', { returnObjects: true }).map((rule, index) => (
          <li key={index}>{rule}</li>
        ))}
      </ul>

      <h2>{t('changesTitle')}</h2>
      <p>{t('changesText')}</p>

      <h2>{t('governingLawTitle')}</h2>
      <p>{t('governingLawText')}</p>
    </div>
  );
};

export default TermsOfService;
