import React, { useState, useEffect, useContext } from 'react';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';
import { Typography, Button, Container } from '@mui/material';
import { AuthContext } from '../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import './VerifyEmail.css';

const VerifyEmail = () => {
  const { t } = useTranslation(['VerifyEmail']);
  const [message, setMessage] = useState('');
  const [resendDisabled, setResendDisabled] = useState(false);
  const [timer, setTimer] = useState(0);
  const navigate = useNavigate();
  const { user, resendEmailVerification } = useContext(AuthContext);

  useEffect(() => {
    if (user && user.confirmed_at) {
      setMessage(t('alreadyVerified'));
      setTimeout(() => {
        navigate('/');
      }, 3000);
      return;
    }

    const verifyEmail = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const accessToken = urlParams.get('access_token');
      const supabaseMessage = urlParams.get('message');
      
      if (accessToken) {
        const { error } = await supabase.auth.sign({
          access_token: accessToken,
        });
        if (error) {
          setMessage(t('verificationFailed'));
        } else if (supabaseMessage) {
          setMessage(decodeURIComponent(supabaseMessage));
        } else {
          setMessage(t('verificationSuccess'));
          setTimeout(() => {
            navigate('/login');
          }, 3000);
        }
      }
    };

    verifyEmail();
  }, [navigate, user, t]);

  useEffect(() => {
    let countdown;
    if (timer > 0) {
      countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    if (timer === 0) {
      setResendDisabled(false);
    }
    return () => clearInterval(countdown);
  }, [timer]);

  const handleResend = async () => {
    setResendDisabled(true);
    setTimer(30);

    if (!user) {
      setMessage(t('userNotLoggedIn'));
      console.error("User not logged in:", user);
      setResendDisabled(false);
      return;
    }

    const error = await resendEmailVerification();
    if (error) {
      setMessage(t('resendFailed'));
      console.error("Error resending verification email:", error);
    } else {
      setMessage(t('resendSuccess'));
    }
  };

  return (
    <Container className="verify-email-container">
      <Typography variant="h5" className="verify-email-message">
        {message || t('verifying')}
      </Typography>
      <Button
        variant="contained"
        onClick={handleResend}
        disabled={resendDisabled}
        className={`resend-button ${resendDisabled ? 'disabled' : ''}`}
      >
        {resendDisabled ? t('resendButtonDisabled', { timer }) : t('resendButtonDefault')}
      </Button>
    </Container>
  );
};

export default VerifyEmail;
