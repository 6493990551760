import React, { useState, useContext } from 'react';
import { supabase } from '../supabaseClient';
import { AuthContext } from '../contexts/AuthContext';
import { validateEmail, validatePassword } from '../utils/validation';
import './EmailPasswordSection.css';

const EmailPasswordSection = () => {
    const { user } = useContext(AuthContext);
    const [email, setEmail] = useState(user?.email || '');
    const [currentPasswordForEmail, setCurrentPasswordForEmail] = useState('');
    const [currentPasswordForPassword, setCurrentPasswordForPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [emailMessage, setEmailMessage] = useState('');
    const [emailMessageType, setEmailMessageType] = useState('');
    const [passwordMessage, setPasswordMessage] = useState('');
    const [passwordMessageType, setPasswordMessageType] = useState('');

    const isEmailProvider = user?.identities?.some(
        (identity) => identity.provider === 'email'
    );

    const handleEmailChange = async () => {
        setEmailMessage('');
        setEmailMessageType('');

        if (email === user.email) {
            setEmailMessage('The new email cannot be the same as the current email.');
            setEmailMessageType('error');
            return;
        }

        if (!validateEmail(email)) {
            setEmailMessage('Invalid email format.');
            setEmailMessageType('error');
            return;
        }

        if (!currentPasswordForEmail) {
            setEmailMessage('Please enter your current password to confirm the email change.');
            setEmailMessageType('error');
            return;
        }

        const { error: signInError } = await supabase.auth.signInWithPassword({
            email: user.email,
            password: currentPasswordForEmail,
        });

        if (signInError) {
            setEmailMessage('Incorrect current password for email change.');
            setEmailMessageType('error');
            return;
        }

        const { error } = await supabase.auth.updateUser({ email });
        if (error) {
            setEmailMessage(error.message);
            setEmailMessageType('error');
        } else {
            setEmailMessage('Email updated. Please verify the new email address in both accounts.');
            setEmailMessageType('success');
        }
    };

    const handlePasswordChange = async () => {
        setPasswordMessage('');
        setPasswordMessageType('');

        if (!newPassword || !confirmNewPassword) {
            setPasswordMessage('Password fields cannot be empty.');
            setPasswordMessageType('error');
            return;
        }

        if (newPassword === currentPasswordForPassword) {
            setPasswordMessage('The new password cannot be the same as the current password.');
            setPasswordMessageType('error');
            return;
        }

        if (newPassword !== confirmNewPassword) {
            setPasswordMessage('Passwords do not match.');
            setPasswordMessageType('error');
            return;
        }

        if (!validatePassword(newPassword)) {
            setPasswordMessage(
                'Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character.'
            );
            setPasswordMessageType('error');
            return;
        }

        if (!currentPasswordForPassword) {
            setPasswordMessage('Please enter your current password to confirm the password change.');
            setPasswordMessageType('error');
            return;
        }

        const { error: signInError } = await supabase.auth.signInWithPassword({
            email: user.email,
            password: currentPasswordForPassword,
        });

        if (signInError) {
            setPasswordMessage('Incorrect current password for password change.');
            setPasswordMessageType('error');
            return;
        }

        const { error } = await supabase.auth.updateUser({ password: newPassword });
        if (error) {
            setPasswordMessage(error.message);
            setPasswordMessageType('error');
        } else {
            setPasswordMessage('Password updated successfully.');
            setPasswordMessageType('success');
        }
    };

    return (
        <div className="email-password-section">
            <h2>Email & Password</h2>

            {isEmailProvider ? (
                <>
                    <div className="form-group">
                        <label>Email</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <label>Current Password (for email change confirmation)</label>
                        <input
                            type="password"
                            value={currentPasswordForEmail}
                            onChange={(e) => setCurrentPasswordForEmail(e.target.value)}
                        />
                    </div>

                    <button
                        onClick={handleEmailChange}
                        className="orange-button"
                    >
                        Change Email
                    </button>
                    {emailMessage && (
                        <p className={`message ${emailMessageType}`}>
                            {emailMessage}
                        </p>
                    )}

                    <div className="form-group">
                        <label>New Password</label>
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <label>Confirm New Password</label>
                        <input
                            type="password"
                            value={confirmNewPassword}
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <label>Current Password (for password change confirmation)</label>
                        <input
                            type="password"
                            value={currentPasswordForPassword}
                            onChange={(e) => setCurrentPasswordForPassword(e.target.value)}
                        />
                    </div>

                    <button
                        onClick={handlePasswordChange}
                        className="orange-button"
                    >
                        Change Password
                    </button>
                    {passwordMessage && (
                        <p className={`message ${passwordMessageType}`}>
                            {passwordMessage}
                        </p>
                    )}
                </>
            ) : (
                <p>
                    Your account is linked with {user?.identities?.[0]?.provider}. Email and
                    password changes are not possible for this provider.
                </p>
            )}
        </div>
    );
};

export default EmailPasswordSection;
