import React, { useState, useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import EmailPasswordSection from '../../components/EmailPasswordSection';
import DataDownload from '../../components/DataDownload';
import AccountDeletion from '../../components/AccountDeletion';
import './MyAccount.css';
import { MenuOpen, Close } from '@mui/icons-material';

const MyAccount = () => {
  const { user } = useContext(AuthContext);
  const [activeSection, setActiveSection] = useState('email-password');
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);

  const handleSectionChange = (section) => {
    setActiveSection(section);
    setIsMobileSidebarOpen(false);
  };

  const toggleMobileSidebar = () => {
    setIsMobileSidebarOpen(!isMobileSidebarOpen);
  };

  const handleOutsideClick = (e) => {
    if (isMobileSidebarOpen && !e.target.closest('.myaccount-sidebar')) {
      setIsMobileSidebarOpen(false);
    }
  };

  return (
    <div className="myaccount-container" onClick={handleOutsideClick}>
      <div
        className={`myaccount-sidebar ${isMobileSidebarOpen ? 'open' : ''}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="mobile-sidebar-header">
          <h2>Settings</h2>
          <Close className="close-icon" onClick={toggleMobileSidebar} />
        </div>
        <ul>
          <li
            onClick={() => handleSectionChange('email-password')}
            className={activeSection === 'email-password' ? 'active' : ''}
          >
            Email & Password
          </li>
          <li
            onClick={() => handleSectionChange('data-download')}
            className={activeSection === 'data-download' ? 'active' : ''}
          >
            Download My Data
          </li>
          <li
            onClick={() => handleSectionChange('account-deletion')}
            className={activeSection === 'account-deletion' ? 'active' : ''}
          >
            Delete My Account
          </li>
        </ul>
      </div>

      <div className="myaccount-content">
        {activeSection === 'email-password' && <EmailPasswordSection />}
        {activeSection === 'data-download' && <DataDownload />}
        {activeSection === 'account-deletion' && <AccountDeletion />}
      </div>

      <MenuOpen className={`menu-icon ${isMobileSidebarOpen ? 'hidden' : ''}`} onClick={toggleMobileSidebar} />
    </div>
  );
};

export default MyAccount;
