import React, { useState, useEffect, useContext } from 'react';
import { supabase } from '../../supabaseClient';
import { AuthContext } from '../../contexts/AuthContext';
import Offer from '../../components/Offer';
import MyOfferEdit from '../../components/MyOfferEdit';
import './MyOffers.css';

const MyOffers = () => {
    const { user } = useContext(AuthContext);
    const [offers, setOffers] = useState([]);
    const [editingOffer, setEditingOffer] = useState(null);
    const [isCreating, setIsCreating] = useState(false);

    const fetchOffers = async () => {
        const { data, error } = await supabase
            .from('offers')
            .select('*')
            .eq('user_id', user.id);

        if (error) {
            console.error('Error fetching offers:', error);
        } else {
            setOffers(data);
        }
    };

    useEffect(() => {

        if (user) {
            fetchOffers();
        }
    }, [user]);

    const handleEdit = (offer) => {
        setEditingOffer(offer);
        setIsCreating(false);
    };

    const handleCreate = () => {
        setEditingOffer(null);
        setIsCreating(true);
    };

    const handleClose = () => {
        setEditingOffer(null);
        setIsCreating(false);
        if (user) {
            fetchOffers();
        }
    };

    return (
        <div className="my-offers-container">
            <h1>My Offers</h1>
            <button className="create-offer-button" onClick={handleCreate}>
                Create New Offer
            </button>
            <div className="offers-list">
                {offers.map((offer) => (
                    <Offer key={offer.id} offer={offer} onEdit={handleEdit} />
                ))}
            </div>
            {(editingOffer !== null || isCreating) && (
                <MyOfferEdit
                    offer={editingOffer}
                    isCreating={isCreating}
                    onClose={handleClose}
                />
            )}
        </div>
    );
};

export default MyOffers;
