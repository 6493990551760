import React, { useEffect } from 'react';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import * as CookieConsent from 'vanilla-cookieconsent';
import { loadScript } from '../utils/loadScript';
import './CookieBanner.css';

const CookieBanner = ({ language }) => {
  useEffect(() => {
    const initializeCookieConsent = async () => {
      try {
        const response = await fetch(`/locales/${language}/CookieConsent.json`);
        if (!response.ok) {
          throw new Error('Failed to load translations');
        }

        const translations = await response.json();

        CookieConsent.run({
          current_lang: language,
          autoclear_cookies: true,
          page_scripts: true,
          categories: {
            necessary: {
              enabled: true,
              readOnly: true,
            },
            analytics: {
              enabled: false,
              autoClear: {
                cookies: [
                  {
                    name: /^_ga/,
                  },
                  {
                    name: '_gid',
                  },
                ],
              },
              services: {
                ga: {
                  label: 'Google Analytics',
                  onAccept: () => {
                    loadGoogleAnalytics();
                  },
                  onReject: () => {
                  },
                },
                youtube: {
                  label: 'Youtube Embed',
                  onAccept: () => {
                    loadYouTubeScripts();
                  },
                  onReject: () => {
                  },
                },
              },
            },
            marketing: {
              enabled: false,
              services: {
                googleSign: {
                  label: 'Google Sign-In',
                  onAccept: () => {
                    loadGoogleSign();
                  },
                  onReject: () => {
                    unloadGoogleSign();
                  },
                },
              },
            },
          },
          language: {
            default: language,
            translations: {
              [language]: translations,
            },
          },
          gui_options: {
            consent_modal: {
              layout: 'box',
              position: 'bottom center',
              transition: 'slide',
            },
            settings_modal: {
              layout: 'box',
              position: 'left',
              transition: 'slide',
            },
          },
          onChange: function ({ changedCategories }) {
            if (changedCategories.includes('marketing')) {
              if (CookieConsent.acceptedCategory('marketing')) {
                loadGoogleSign();
              } else {
                unloadGoogleSign();
              }
            }
          },
        });
      } catch (error) {
        console.error('Failed to load cookie consent translations:', error);
      }
    };

    initializeCookieConsent();
  }, [language]); // Re-run the effect whenever the language prop changes

  const loadGoogleAnalytics = () => {
    loadScript(
      'https://www.googletagmanager.com/gtag/js?id=GA_MEASUREMENT_ID',
      'google-analytics',
      () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() { window.dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'GA_MEASUREMENT_ID');
      }
    );
  };

  const loadGoogleSign = () => {
    console.log('Loading Google Sign-In script');
    loadScript(
      'https://accounts.google.com/gsi/client',
      'google-client-script',
      () => {
        if (window.google) {
          console.log('Initializing Google Sign-In');
          const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
          window.google.accounts.id.initialize({
            client_id: clientId,
            callback: () => {
            },
          });
        }
      }
    );
  };

  const unloadGoogleSign = () => {
    console.log('Unloading Google Sign-In script');
    const script = document.getElementById('google-client-script');
    if (script) {
      script.remove();
    }
  };

  const loadYouTubeScripts = () => {
  };

  return null;
};

export default CookieBanner;