import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import { saveAs } from 'file-saver';

const DataDownload = () => {
  const [message, setMessage] = useState('');

  const handleDataDownload = async () => {
    setMessage(''); // Clear previous messages
    try {
      // Retrieve the user asynchronously
      const { data: userData, error: userError } = await supabase.auth.getUser();

      if (userError || !userData) {
        setMessage('Error retrieving user data.');
        return;
      }

      // Call the Supabase RPC function to handle data download
      const { data, error } = await supabase.rpc('handle_data_download', {
        user_uuid: userData.user.id,
      });

      if (error) {
        setMessage(error.message); // Set error message if an exception is thrown
        return;
      }

      // Check if the response contains an error
      if (data.error) {
        // If there is remaining time, display it in the message
        if (data.remaining_time) {
          const minutesRemaining = Math.ceil(data.remaining_time);
          setMessage(`You can only download your data once every hour. Please try again in ${minutesRemaining} minutes.`);
        } else {
          setMessage(data.error); // Display error message
        }
        return;
      }

      // Convert the response to JSON and trigger download if no error
      if (data) {
        const jsonData = JSON.stringify(data);
        const blob = new Blob([jsonData], { type: 'application/json' });
        saveAs(blob, 'user_data.json');
        setMessage('Data downloaded successfully.');
      }
    } catch (err) {
      setMessage(err.message || 'An unexpected error occurred.'); // Gracefully handle any unexpected error
    }
  };

  return (
    <div>
      <h2>Download My Data</h2>
      <button className='orange-button' onClick={handleDataDownload}>Download Data</button>
      {message && <p>{message}</p>}
    </div>
  );
};

export default DataDownload;
