import React, { useContext, useState } from 'react';
import { Drawer, IconButton, Box, Button, Typography, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MapIcon from '@mui/icons-material/Map';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PersonIcon from '@mui/icons-material/Person';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../contexts/AuthContext';
import SearchBar from './SearchBar';
import { Link } from 'react-router-dom';
import LanguageSwitcher from './LanguageSwitcher';
import './MobileNavbar.css';

const MobileNavbar = () => {
    const { t } = useTranslation(['NavBar', 'Common']);
    const { user, signOut } = useContext(AuthContext);
    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const getUserName = () => {
        if (user) {
            const { firstName, lastName, name } = user.user_metadata;
            if (firstName && lastName) {
                return `${firstName} ${lastName}`;
            } else if (name) {
                return name;
            }
        }
        return '';
    };

    return (
        <Box className="mobile-navbar-container">
            <div className="left-container">
                <Link to="/" className="logo-link">
                    <img src="/logo_square.png" alt="Logo" className="logoMobile" />
                </Link>
                <div className="vertical-spacer"></div>
                <div className="search-bar-container">
                    <SearchBar showLocationSearch={false} />
                </div>
            </div>
            <IconButton edge="end" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                <MenuIcon />
            </IconButton>
            <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
                <Box
                    className="mobile-menu"
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <Box className="user-info">
                        {user ? (
                            <Typography variant="h6">{getUserName()}</Typography>
                        ) : (
                            <Link to="/login">
                                <Button className="login-button">{t('Common:login')}</Button>
                            </Link>
                        )}
                    </Box>
                    <Divider />
                    {user && (
                        <>
                            <Link to="/my-orders">
                                <Button className="menu-button">
                                    <ShoppingBasketIcon className="menu-icon" />
                                    {t('Common:myOrders')}
                                </Button>
                            </Link>
                            <Link to="/my-offers">
                                <Button className="menu-button">
                                    <StorefrontIcon className="menu-icon" />
                                    {t('Common:myOffers')}
                                </Button>
                            </Link>
                            <Link to="/my-profile">
                                <Button className="menu-button">
                                    <PersonIcon className="menu-icon" />
                                    {t('Common:myProfile')}
                                </Button>
                            </Link>
                            <Link to="/my-account">
                                <Button className="menu-button">
                                    <ManageAccountsIcon className="menu-icon" />
                                    {t('Common:myAccount')}
                                </Button>
                            </Link>
                            <Divider />
                        </>
                    )}
                    <Link to="/heroradar-map">
                        <Button className="menu-button">
                            <MapIcon className="menu-icon" />
                            {t('heroradarMap')}
                        </Button>
                    </Link>
                    <Link to="/anzeige-aufgaben">
                        <Button className="menu-button">
                            <LocalOfferIcon className="menu-icon" />
                            {t('offerServices')}
                        </Button>
                    </Link>
                    {user && (
                        <>
                            <Button className="menu-button logout-oange" onClick={signOut}>
                                <LogoutIcon className="menu-icon" />
                                {t('NavbarAuthenticatedDropdownMenu:logout')}
                            </Button>
                        </>
                    )}
                    <Divider />
                    <LanguageSwitcher />
                </Box>
            </Drawer>
        </Box>
    );
};

export default MobileNavbar;
