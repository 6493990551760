import React, { useState, useEffect, useContext } from 'react';
import { supabase } from '../supabaseClient';
import './MyOfferEdit.css';
import { AuthContext } from '../contexts/AuthContext';
import LocationSelector from './LocationSelector';

const MyOfferEdit = ({ offer, onClose, isCreating }) => {
  const { user } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    title: '',
    short_description: '',
    flat_price: 0,
    hourly_rate: 0,
    currency: '',
    min_time_minutes: 0,
    delivery_radius: 0,
    free_delivery_radius: 0,
    price_per_km: 0,
    flat_price_travel: 0,
    countries: [],
    regions: [],
    cities: [],
    subcategories: '',
    long_description: '',
    contract_url: '',
    offer_image_url: '',
    media_urls: '',
    youtube_link: '',
    website: '',
    offer_end_date: '',
    is_sale: false,
    sale_percent: 0,
  });

  const [priceType, setPriceType] = useState('hourly');
  const [locations, setLocations] = useState([]);
  const [isGlobal, setIsGlobal] = useState(false);

  useEffect(() => {
    if (offer) {
      setFormData({ 
        ...offer,
        countries: offer.countries || [],
        regions: offer.regions || [],
        cities: offer.cities || [],
        offer_end_date: offer.offer_end_date ? offer.offer_end_date.slice(0, 16) : ''
      });
      setPriceType(offer.hourly_rate > 0 ? 'hourly' : 'flat');
      setIsGlobal(!offer.countries.length && !offer.regions.length && !offer.cities.length);

      const initialLocations = [
        ...offer.countries.map((country) => ({ country, region: null, city: null })),
        ...offer.regions.map((region) => ({ country: null, region, city: null })),
        ...offer.cities.map((city) => ({ country: null, region: null, city })),
      ];
      setLocations(initialLocations);
    } else {
      setFormData({
        title: '',
        short_description: '',
        flat_price: 0,
        hourly_rate: 0,
        currency: '',
        min_time_minutes: 0,
        delivery_radius: 0,
        free_delivery_radius: 0,
        price_per_km: 0,
        flat_price_travel: 0,
        countries: [],
        regions: [],
        cities: [],
        subcategories: '',
        long_description: '',
        contract_url: '',
        offer_image_url: '',
        media_urls: '',
        youtube_link: '',
        website: '',
        offer_end_date: '',
        is_sale: false,
        sale_percent: 0,
      });
      setPriceType('hourly');
    }
  }, [offer, isCreating]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handlePriceTypeChange = (e) => {
    setPriceType(e.target.value);
    if (e.target.value === 'hourly') {
      setFormData((prev) => ({ ...prev, flat_price: 0 }));
    } else {
      setFormData((prev) => ({ ...prev, hourly_rate: 0, min_time_minutes: 0 }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const countryIds = locations.filter(loc => loc.country && !loc.region && !loc.city).map(loc => loc.country);
    const regionIds = locations.filter(loc => loc.region && !loc.city).map(loc => loc.region);
    const cityIds = locations.filter(loc => loc.city).map(loc => loc.city);

    try {
      const offerData = {
        ...formData,
        countries: isGlobal ? [] : countryIds,
        regions: isGlobal ? [] : regionIds,
        cities: isGlobal ? [] : cityIds,
        subcategories: formData.subcategories.split(',').map(Number),
        media_urls: formData.media_urls.split(',').map((url) => url.trim()),
        offer_end_date: formData.offer_end_date || null, // Handle empty date
      };

      if (offer) {
        // Update existing offer
        const { error } = await supabase
          .from('offers')
          .update(offerData)
          .eq('id', offer.id);

        if (error) throw error;
      } else {
        // Create new offer
        const { error } = await supabase.from('offers').insert([
          {
            ...offerData,
            user_id: user.id,
          },
        ]);

        if (error) throw error;
      }
      onClose();
    } catch (error) {
      console.error('Error submitting offer:', error);
    }
  };

  return (
    <div className="my-offer-edit-container">
      <h2>{offer ? 'Edit Offer' : 'Create Offer'}</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">
            Title *
            <span className="description"> The title of the offer</span>
          </label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="short_description">
            Short Description *
            <span className="description"> A brief overview of the offer (max 300 characters)</span>
          </label>
          <textarea
            id="short_description"
            name="short_description"
            value={formData.short_description}
            onChange={handleChange}
            maxLength={300}
            required
          ></textarea>
        </div>

        <div className="form-group">
          <label>
            Locations *
            <span className="description">Select where the offer is applicable</span>
          </label>
          <LocationSelector
            onChange={setLocations}
            isGlobal={isGlobal}
            setIsGlobal={setIsGlobal}
            initialLocations={locations}
          />
        </div>

        <div className="form-group">
          <label htmlFor="price_type">
            Pricing Type *
            <span className="description"> Select pricing type for the offer</span>
          </label>
          <select
            id="price_type"
            name="price_type"
            value={priceType}
            onChange={handlePriceTypeChange}
          >
            <option value="hourly">Hourly Rate</option>
            <option value="flat">Flat Price</option>
          </select>
        </div>

        {priceType === 'hourly' ? (
          <>
            <div className="form-group">
              <label htmlFor="hourly_rate">
                Hourly Rate *
                <span className="description"> The hourly charge rate</span>
              </label>
              <input
                type="number"
                id="hourly_rate"
                name="hourly_rate"
                value={formData.hourly_rate}
                onChange={handleChange}
                min={0}
                step="0.01"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="min_time_minutes">
                Minimum Time (minutes)
                <span className="description"> Minimum billing time for hourly services</span>
              </label>
              <input
                type="number"
                id="min_time_minutes"
                name="min_time_minutes"
                value={formData.min_time_minutes}
                onChange={handleChange}
                min={0}
              />
            </div>
          </>
        ) : (
          <div className="form-group">
            <label htmlFor="flat_price">
              Flat Price *
              <span className="description"> The fixed price for the service</span>
            </label>
            <input
              type="number"
              id="flat_price"
              name="flat_price"
              value={formData.flat_price}
              onChange={handleChange}
              min={0}
              step="0.01"
              required
            />
          </div>
        )}

        <div className="form-group">
          <label htmlFor="currency">
            Currency *
            <span className="description"> Currency code for pricing</span>
          </label>
          <input
            type="text"
            id="currency"
            name="currency"
            value={formData.currency}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="delivery_radius">
            Delivery Radius (km)
            <span className="description"> Max distance for delivery services</span>
          </label>
          <input
            type="number"
            id="delivery_radius"
            name="delivery_radius"
            value={formData.delivery_radius}
            onChange={handleChange}
            min={0}
          />
        </div>

        {formData.delivery_radius > 0 && (
          <div className="form-group">
            <label htmlFor="free_delivery_radius">
              Free Delivery Radius (km)
              <span className="description"> Distance within which delivery is free</span>
            </label>
            <input
              type="number"
              id="free_delivery_radius"
              name="free_delivery_radius"
              value={formData.free_delivery_radius}
              onChange={handleChange}
              min={0}
            />
          </div>
        )}

        {formData.delivery_radius > 0 && (
          <>
            <div className="form-group">
              <label htmlFor="price_per_km">
                Price per km
                <span className="description"> Cost per kilometer for travel</span>
              </label>
              <input
                type="number"
                id="price_per_km"
                name="price_per_km"
                value={formData.price_per_km}
                onChange={handleChange}
                min={0}
                step="0.01"
                disabled={formData.flat_price_travel > 0}
              />
            </div>

            <div className="form-group">
              <label htmlFor="flat_price_travel">
                Flat Price for Travel
                <span className="description"> Fixed cost for travel expenses</span>
              </label>
              <input
                type="number"
                id="flat_price_travel"
                name="flat_price_travel"
                value={formData.flat_price_travel}
                onChange={handleChange}
                min={0}
                step="0.01"
                disabled={formData.price_per_km > 0}
              />
            </div>
          </>
        )}

        <div className="form-group">
          <label htmlFor="long_description">
            Long Description *
            <span className="description"> Detailed information about the offer (max 5000 characters)</span>
          </label>
          <textarea
            id="long_description"
            name="long_description"
            value={formData.long_description}
            onChange={handleChange}
            maxLength={5000}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="contract_url">
            Contract URL
            <span className="description"> URL for the service contract</span>
          </label>
          <input
            type="url"
            id="contract_url"
            name="contract_url"
            value={formData.contract_url}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="offer_image_url">
            Offer Image URL
            <span className="description"> URL for the offer image</span>
          </label>
          <input
            type="url"
            id="offer_image_url"
            name="offer_image_url"
            value={formData.offer_image_url}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="media_urls">
            Media URLs (comma-separated)
            <span className="description"> List of media URLs for the offer</span>
          </label>
          <input
            type="text"
            id="media_urls"
            name="media_urls"
            value={formData.media_urls}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="youtube_link">
            YouTube Link
            <span className="description"> Link to a YouTube video related to the offer</span>
          </label>
          <input
            type="text"
            id="youtube_link"
            name="youtube_link"
            value={formData.youtube_link}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="website">
            Website
            <span className="description"> Website associated with the offer</span>
          </label>
          <input
            type="text"
            id="website"
            name="website"
            value={formData.website}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="offer_end_date">
            Offer End Date
            <span className="description"> End date for the offer validity</span>
          </label>
          <input
            type="datetime-local"
            id="offer_end_date"
            name="offer_end_date"
            value={formData.offer_end_date}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="is_sale">
            Is Sale?
            <span className="description"> Indicates if the offer is currently on sale</span>
          </label>
          <input
            type="checkbox"
            id="is_sale"
            name="is_sale"
            checked={formData.is_sale}
            onChange={handleChange}
          />
        </div>
        {formData.is_sale && (
          <div className="form-group">
            <label htmlFor="sale_percent">
              Discount in Percent %
              <span className="description"> Percentage discount for the sale</span>
            </label>
            <input
              type="number"
              id="sale_percent"
              name="sale_percent"
              value={formData.sale_percent}
              onChange={handleChange}
              min={0}
            />
          </div>
        )}

        <button type="submit">{offer ? 'Update Offer' : 'Create Offer'}</button>
        <button type="button" onClick={onClose}>
          Cancel
        </button>
      </form>
    </div>
  );
};

export default MyOfferEdit;
