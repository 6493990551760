import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { Link as MuiLink } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import EditIcon from '@mui/icons-material/Edit';
import { AuthContext } from '../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import './Profile.css';

const Profile = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { t } = useTranslation('Profile');
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isOwnProfile, setIsOwnProfile] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      console.log(`Fetching profile for username: ${username}`);

      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('username', username)
        .single();

      if (error || !data) {
        console.error('Profile not found or error occurred:', error);
        setProfile(null);
      } else {
        console.log('Profile data found:', data);
        setProfile(data);

        if (user) {
          const { data: userProfile, error: userProfileError } = await supabase
            .from('profiles')
            .select('username')
            .eq('id', user.id)
            .single();

          if (userProfileError) {
            console.error('Error fetching user profile:', userProfileError);
          } else if (userProfile && userProfile.username === username) {
            setIsOwnProfile(true);
          }
        }
      }
      setLoading(false);
    };

    fetchProfile();
  }, [username, user]);

  if (loading) {
    return <div>{t('loading')}</div>;
  }

  const handleEditProfile = () => {
    navigate('/my-profile');
  };

  return (
    <div className="profile-container">
      {profile ? (
        <div className="profile-content">
          <div className="profile-header">
            <img
              src={profile.avatar_url || '/default-avatar.png'}
              alt={`${profile.username}'s ${t('avatarAlt')}`}
              className="profile-avatar"
            />
            <div className="profile-info">
              <h1>{profile.full_name}</h1>
              {profile.profile_title && <h2>{profile.profile_title}</h2>}
              {profile.bio && <p className="profile-bio">{profile.bio}</p>}
              <p className="profile-joined">
                <strong>{t('joined')}:</strong> {new Date(profile.created_at).toLocaleDateString()}
              </p>
              {profile.website && (
                <p className="profile-website">
                  <LanguageIcon />
                  <MuiLink href={profile.website} target="_blank" rel="noopener noreferrer">
                    {profile.website}
                  </MuiLink>
                </p>
              )}
            </div>
          </div>
          {isOwnProfile && (
            <button className="edit-profile-button" onClick={handleEditProfile}>
              <EditIcon style={{ marginRight: '8px' }} />
              {t('editProfile')}
            </button>
          )}
        </div>
      ) : (
        <div>{t('profileNotFound')}</div>
      )}
    </div>
  );
};

export default Profile;
