import React, { useContext } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import SearchBar from './SearchBar';
import { AuthContext } from '../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';
import NavbarAuthenticatedDropdownMenu from './NavbarAuthenticatedDropdownMenu';
import MobileNavbar from './MobileNavbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import './NavBar.css';

const NavBar = () => {
  const { t } = useTranslation(['NavBar', 'Common']);
  const { user, signOut } = useContext(AuthContext);

  console.log('Translation for heroradarMap:', t('heroradarMap'));
  console.log('Translation for offerServices:', t('offerServices'));

  const isMobile = useMediaQuery('(max-width:1024px)');


  const getUserName = () => {
    if (user) {
      const { firstName, lastName, name } = user.user_metadata;
      if (firstName && lastName) {
        return `${firstName} ${lastName}`;
      } else if (name) {
        return name;
      }
    }
    return '';
  };

  return (
    <Box className="navbar-container">
        {isMobile ? (
            <MobileNavbar />
        ) : (
            <>
                <Box className="top-bar">
                    <div className="logo-container">
                        <Link to="/">
                            <img src="/logo_landscape.png" alt="Logo" className="logo" />
                        </Link>
                    </div>
                    <LanguageSwitcher />
                    <div className="login-signup-container">
                        {user ? (
                            <Box className="auth-buttons">
                                <NavbarAuthenticatedDropdownMenu userName={getUserName()} />
                            </Box>
                        ) : (
                            <Box className="auth-buttons">
                                <Link to="/signup">
                                    <Button className="orange-button-text-color">{t('Common:signUp')}</Button>
                                </Link>
                                <span>{t('Common:or')}</span>
                                <Link to="/login">
                                    <Button className="orange-button-text-color">{t('Common:login')}</Button>
                                </Link>
                            </Box>
                        )}
                    </div>
                </Box>
                <Box className="search-bar">
                    <div className="search-bar-center">
                        <SearchBar showLocationSearch={true} />
                        <div className="justify-right-side">
                            <Button className="search-bar-button">{t('heroradarMap')}</Button>
                            <Button className="search-bar-button">{t('offerServices')}</Button>
                        </div>
                    </div>
                </Box>
            </>
        )}
    </Box>
);
};

export default NavBar;