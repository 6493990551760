import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import { Container, TextField, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';


const ResetPassword = () => {
  const { t } = useTranslation(['Common', 'ResetPassword']);
  const [email, setEmail] = useState('');

  const handleResetPassword = async () => {
    const { error } = await supabase.auth.api.resetPasswordForEmail(email);
    if (error) console.error('Error resetting password:', error.message);
    else console.log('Password reset email sent');
  };

  return (
    <Container>
      <Typography variant="h4">{t('resetPassword')}</Typography>
      <TextField
        label={t('email')}
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button variant="contained" onClick={handleResetPassword}>
        {t('resetPassword')}
      </Button>
    </Container>
  );
};

export default ResetPassword;
