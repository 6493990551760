import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './NotFoundPage.css';

const NotFoundPage = () => {
  const { t } = useTranslation(['NotFoundPage']);
  const [heroPosition, setHeroPosition] = useState({ top: 20, left: 50 });
  const [targetPosition, setTargetPosition] = useState({ top: 25, left: 0 });
  const [score, setScore] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    const spawnTarget = () => {
      let newTop, newLeft;

      do {
        newTop = Math.random() * 60 + 10;
        newLeft = Math.random() * 70 + 10;
      } while (newTop > 60 && newLeft > 20 && newLeft < 80);

      setTargetPosition({ top: newTop, left: newLeft });
    };

    spawnTarget(); // Initial target spawn

    //const intervalId = setInterval(spawnTarget, 3000); // Spawn every 3 seconds

    //return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setElapsedTime((prevTime) => prevTime + 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleTargetClick = () => {
    setScore((prevScore) => prevScore + 1);
    setHeroPosition(targetPosition);
    spawnNewTarget();
  };

  const spawnNewTarget = () => {
    let newTop, newLeft;

    do {
      newTop = Math.random() * 60 + 10;
      newLeft = Math.random() * 70 + 10;
    } while (newTop > 60 && newLeft > 20 && newLeft < 80);

    setTargetPosition({ top: newTop, left: newLeft });
  };

  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs
      .toString()
      .padStart(2, '0')}`;
  };

  return (
    <div className="not-found-container">
      <div className="score-container">
        <span className="score-text">{t('score')}:</span> <span className="score-value">{score}</span>
      </div>
      <div className="timer-container">
        <span className="timer-text">{t('time')}:</span> <span className="timer-value">{formatTime(elapsedTime)}</span>
      </div>
      <div
        className="hero-animation"
        style={{
          top: `${heroPosition.top}%`,
          left: `${heroPosition.left}%`,
        }}
      >
        <img
          src="/hero.png"
          alt="Hero"
          className="hero-image"
        />
      </div>
      <div
        className="target"
        style={{
          top: `${targetPosition.top}%`,
          left: `${targetPosition.left}%`,
        }}
        onClick={handleTargetClick}
      >
        <img
          src="/target.png"
          alt="Target"
          className="target-image"
        />
      </div>
      <div className="text-container">
        <h1>{t('title')}</h1>
        <p>{t('description')}</p>
        <Link to="/">
          <button className="home-button">{t('backHome')}</button>
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
