import React from 'react';
import { useTranslation } from 'react-i18next';
import './Imprint.css';

const Imprint = () => {
  const { t } = useTranslation('Imprint');

  return (
    <div className="imprint-container">
      <h1>{t('title')}</h1>
      <p>{t('operator')}</p>
      <p>{t('businessName')}</p>
      <p>{t('addressLine1')}</p>
      <p>{t('addressLine2')}</p>
      <p>{t('court')}</p>
      <a href={`mailto:${t('email')}`}>{t('email')}</a>
      <p>{t('lastUpdated')}</p>
    </div>
  );
};

export default Imprint;
