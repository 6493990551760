import React from 'react';
import './Offer.css';

const Offer = ({ offer, onEdit }) => {
  const handleEditClick = () => {
    onEdit(offer);
  };

  return (
    <div className="offer-card">
      <h2>{offer.title}</h2>
      <p>{offer.short_description}</p>
      <p>Flat Price: {offer.flat_price}</p>
      <p>Hourly Rate: {offer.hourly_rate}</p>
      <button onClick={handleEditClick}>Edit Offer</button>
    </div>
  );
};

export default Offer;
