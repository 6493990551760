import React from 'react';
import { useTranslation } from 'react-i18next';
import './DataPrivacy.css';

const DataPrivacy = () => {
    const { t } = useTranslation('DataPrivacy');

    return (
        <div className="data-privacy-container">
            <h1>{t('title')}</h1>
            <p>{t('introduction')}</p>

            <h2>{t('sections.importantInfo.title')}</h2>
            <p>{t('sections.importantInfo.content')}</p>

            <h3>{t('sections.importantInfo.contactTitle')}</h3>
            <p>{t('sections.importantInfo.contactInfo')}</p>
            <a href={`mailto:${t('sections.importantInfo.email')}`}>{t('sections.importantInfo.email')}</a>

            <h2>{t('sections.dataWeCollect.title')}</h2>
            <p>{t('sections.dataWeCollect.content')}</p>
            <ul>
                <li>{t('sections.dataWeCollect.dataList.identityData')}</li>
                <li>{t('sections.dataWeCollect.dataList.contactData')}</li>
                <li>{t('sections.dataWeCollect.dataList.technicalData')}</li>
                <li>{t('sections.dataWeCollect.dataList.usageData')}</li>
                <li>{t('sections.dataWeCollect.dataList.locationData')}</li>
                <li>{t('sections.dataWeCollect.dataList.contentData')}</li>
                <li>{t('sections.dataWeCollect.dataList.securityData')}</li>
                <li>{t('sections.dataWeCollect.dataList.marketingData')}</li>
            </ul>

            <h2>{t('sections.howWeCollectData.title')}</h2>
            <p>{t('sections.howWeCollectData.content')}</p>
            <ul>
                <li>{t('sections.howWeCollectData.methodsList.directInteractions')}</li>
                <li>{t('sections.howWeCollectData.methodsList.automatedInteractions')}</li>
                <li>{t('sections.howWeCollectData.methodsList.thirdPartySources')}</li>
            </ul>

            <h2>{t('sections.howWeUseData.title')}</h2>
            <p>{t('sections.howWeUseData.content')}</p>
            <ul>
                <li>{t('sections.howWeUseData.usesList.toRegister')}</li>
                <li>{t('sections.howWeUseData.usesList.toProcessTransactions')}</li>
                <li>{t('sections.howWeUseData.usesList.toManageRelationship')}</li>
                <li>{t('sections.howWeUseData.usesList.toEnhanceServices')}</li>
                <li>{t('sections.howWeUseData.usesList.toAdministerAndProtect')}</li>
                <li>{t('sections.howWeUseData.usesList.toShowcaseContent')}</li>
                <li>{t('sections.howWeUseData.usesList.toDeliverContent')}</li>
                <li>{t('sections.howWeUseData.usesList.toMonitorSecurity')}</li>
                <li>{t('sections.howWeUseData.usesList.toMakeSuggestions')}</li>
            </ul>

            <h2>{t('sections.cookies.title')}</h2>
            <p>{t('sections.cookies.content')}</p>

            <h3>{t('sections.cookies.typesTitle')}</h3>
            <ul>
                <li>{t('sections.cookies.typesList.strictlyNecessary')}</li>
                <li>{t('sections.cookies.typesList.analyticalPerformance')}</li>
                <li>{t('sections.cookies.typesList.functionality')}</li>
                <li>{t('sections.cookies.typesList.targeting')}</li>
            </ul>

            <p>{t('sections.cookies.management')}</p>

            <h2>{t('sections.googleAnalytics.title')}</h2>
            <p>{t('sections.googleAnalytics.content')}</p>
            <p>{t('sections.googleAnalytics.optOut')}</p>

            <h2>{t('sections.dataSecurity.title')}</h2>
            <p>{t('sections.dataSecurity.content')}</p>

            <h2>{t('sections.dataRetention.title')}</h2>
            <p>{t('sections.dataRetention.content')}</p>

            <h2>{t('sections.legalRights.title')}</h2>
            <p>{t('sections.legalRights.content')}</p>
            <ul>
                <li>{t('sections.legalRights.rightsList.requestAccess')}</li>
                <li>{t('sections.legalRights.rightsList.requestCorrection')}</li>
                <li>{t('sections.legalRights.rightsList.requestErasure')}</li>
                <li>{t('sections.legalRights.rightsList.objectToProcessing')}</li>
                <li>{t('sections.legalRights.rightsList.requestRestriction')}</li>
                <li>{t('sections.legalRights.rightsList.requestTransfer')}</li>
                <li>{t('sections.legalRights.rightsList.withdrawConsent')}</li>
            </ul>

            <p>{t('sections.legalRights.contact')}</p>

            <h2>{t('sections.thirdPartyLinks.title')}</h2>
            <p>{t('sections.thirdPartyLinks.content')}</p>

            <h2>{t('sections.changesToPolicy.title')}</h2>
            <p>{t('sections.changesToPolicy.content')}</p>
            <p>{t('sections.changesToPolicy.lastUpdated')}</p>
        </div>
    );
};

export default DataPrivacy;
