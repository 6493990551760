import React, { useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './SearchBar.css';

const SearchBar = ({ showLocationSearch = false }) => {
  const { t } = useTranslation(['SearchBar']);

  const [service, setService] = useState('');
  const [location, setLocation] = useState('');

  const handleSearch = () => {
    // Implement search functionality here
    console.log('Searching for:', service, location);
  };

  return (
    <Box className="search-bar-container">
      <TextField
        label={t('whatAreYouSearching')}
        variant="outlined"
        value={service}
        onChange={(e) => setService(e.target.value)}
        className="search-input"
      />
      {showLocationSearch && (
        <TextField
          label={t('zipCodeOrCity')}
          variant="outlined"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          className="search-input"
        />
      )}
      <Button onClick={handleSearch} className="search-bar-button">
      {t('search')}
      </Button>
    </Box>
  );
};

export default SearchBar;
