// src/components/NavbarAuthenticatedDropdownMenu.js
import React, { useContext } from 'react';
import { Menu, MenuItem, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../contexts/AuthContext';
import PersonIcon from '@mui/icons-material/Person';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LogoutIcon from '@mui/icons-material/Logout';
import './NavbarAuthenticatedDropdownMenu.css';

const NavbarAuthenticatedDropdownMenu = ({ userName }) => {
    const { t } = useTranslation(['Common', 'NavbarAuthenticatedDropdownMenu']);

    const { user, signOut } = useContext(AuthContext);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseAndSignOut = () => {
        signOut();
        handleClose();
    };

    return (
        <div className="person-menu">
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                {userName}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                classes={{ paper: 'menu-paper' }}
            >
                <MenuItem onClick={handleClose} component={Link} to="/my-orders">
                    <ShoppingBasketIcon className="menu-icon" />
                    {t('myOrders')}
                </MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/my-offers">
                    <StorefrontIcon className="menu-icon" />
                    {t('myOffers')}
                </MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/my-profile">
                    <PersonIcon className="menu-icon" />
                    {t('myProfile')}
                </MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/my-account">
                    <ManageAccountsIcon className="menu-icon" />
                    {t('myAccount')}
                </MenuItem>
                <MenuItem onClick={handleCloseAndSignOut} className="logout-menu-item">
                    <LogoutIcon className="menu-icon" />
                    {t('NavbarAuthenticatedDropdownMenu:logout')}
                </MenuItem>
            </Menu>
        </div>
    );
};

export default NavbarAuthenticatedDropdownMenu;
