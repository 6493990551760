import React, { useState } from 'react';
import { Box, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import i18n from 'i18next';
import TranslateIcon from '@mui/icons-material/Translate';
import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.css';
import CookieBanner from './CookieBanner';

const LanguageSwitcher = () => {
  const { t } = useTranslation(['LanguageSwitcher']);
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language.split('-')[0]);

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
    setCurrentLanguage(selectedLanguage);
  };

  return (
    <Box className="language-switcher">
      <TranslateIcon id="translate-icon" />
      <FormControl variant="outlined" className="language-form-control">
        <InputLabel id="language-select-label">{t('language')}</InputLabel>
        <Select
          labelId="language-select-label"
          id="language-select"
          onChange={changeLanguage}
          label={t('language')}
          value={currentLanguage}
        >
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="de">Deutsch</MenuItem>
        </Select>
      </FormControl>
      <CookieBanner language={currentLanguage} />
    </Box>
  );
};

export default LanguageSwitcher;
