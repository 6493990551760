import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './Footer.css';

const Footer = () => {
    const { t } = useTranslation('Footer');
    const currentYear = new Date().getFullYear();

    return (
        <footer>
            <img src="/heroradar_footer.png" alt={t('footerImageAlt')} className="footer-image" />
            <div className="footer-container">
                <Box className="footer-content">
                    <Box className="footer-links">
                        <Link to="/data-privacy" className="footer-link">
                            {t('dataPrivacy')}
                        </Link>
                        <Link to="/imprint" className="footer-link">
                            {t('imprint')}
                        </Link>
                        <Link to="/tos" className="footer-link">
                            {t('termsOfService')}
                        </Link>
                    </Box>
                    <Typography variant="body2" className="footer-copyright">
                        © {currentYear} HeroRadar. {t('allRightsReserved')}
                    </Typography>
                </Box>
            </div>
        </footer>
    );
};

export default Footer;
