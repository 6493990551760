// src/pages/SignUpPage.js
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box } from '@mui/material';
import SignUpLogin from '../components/SignUpLogin';
import { AuthContext } from '../contexts/AuthContext';
import './SignUpPage.css';

const SignUpPage = ({ isSignUp: initialIsSignUp, redirectPath }) => {
  const [isSignUp, setIsSignUp] = useState(initialIsSignUp);
  const { user } = useContext(AuthContext); 
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  return (
    <div className="backgroundContainer-landing">
      <Container>
        <Box className="landing-page-content">
          <Box className="logo-container">
            <img src="/logo.png" alt="HeroRadar Logo" className="logo-fullsize" />
          </Box>
          <Box className="signup-login-container">
            <SignUpLogin isSignUp={isSignUp} redirectPath={redirectPath} />
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default SignUpPage;
