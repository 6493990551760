import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
        fallbackLng: 'en',
        ns: [
            'Common',
            'DataPrivacy',
            'Footer',
            'Imprint',
            'LanguageSwitcher',
            'NavBar',
            'NavbarAuthenticatedDropdownMenu',
            'NotFoundPage',
            'MyProfile',
            'Profile',
            'ResetPassword',
            'SearchBar',
            'SignUpLogin',
            'TermsOfService',
            'VerifyEmail'
        ],
        defaultNS: 'Common',
        interpolation: {
            escapeValue: false,
        }
    });

export default i18n;