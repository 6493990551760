import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import { TextField, MenuItem, Typography } from '@mui/material';

const LocationSearch = ({ onSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = async (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    if (term.length === 0) {
      setSearchResults([]);
      return;
    }

    try {
      // Fetch countries
      const { data: countryMatches, error: countryError } = await supabase
        .from('countries')
        .select('*')
        .ilike('name', `%${term}%`)
        .limit(20);

      // Fetch regions with their related country names
      const { data: regionMatches, error: regionError } = await supabase
        .from('regions')
        .select(`
          id,
          name,
          country_id,
          countries (name)
        `)
        .ilike('name', `%${term}%`)
        .limit(20);

      // Fetch cities with their related region and country names
      const { data: cityMatches, error: cityError } = await supabase
        .from('cities')
        .select(`
          id,
          name,
          region_id,
          regions (name, countries (name))
        `)
        .ilike('name', `%${term}%`)
        .limit(20);

      if (countryError) throw countryError;
      if (regionError) throw regionError;
      if (cityError) throw cityError;

      // Format the results for display, with safe fallback values
      setSearchResults([
        ...countryMatches.map((country) => ({
          type: 'country',
          id: country.id,
          name: country.name,
          display: country.name,
        })),
        ...regionMatches.map((region) => ({
          type: 'region',
          id: region.id,
          name: region.name,
          display: `${region.name}, ${region.countries?.name || 'Unknown Country'}`,
        })),
        ...cityMatches.map((city) => ({
          type: 'city',
          id: city.id,
          name: city.name,
          display: `${city.name}, ${city.regions?.name || 'Unknown Region'}, ${
            city.regions?.countries?.name || 'Unknown Country'
          }`,
        })),
      ].slice(0, 20));  // Optionally limit to 20 results
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  const handleSelect = async (location) => {
    const selectedItem = searchResults.find((result) => result.id === location.id && result.type === location.type);
  
    if (selectedItem) {
      if (selectedItem.type === 'city') {
        if (!selectedItem.regions || !selectedItem.regions.countries) {
          const cityData = await supabase
            .from('cities')
            .select('id, name, region_id, regions(id, name, country_id, countries(id, name))')
            .eq('id', selectedItem.id)
            .single();
  
          onSelect({
            type: 'city',
            id: cityData.data.id,
            cityName: cityData.data.name,
            regionId: cityData.data.regions?.id,  // Pass region ID
            regionName: cityData.data.regions?.name || 'Unknown Region',
            countryId: cityData.data.regions?.countries?.id,  // Pass country ID
            countryName: cityData.data.regions?.countries?.name || 'Unknown Country',
          });
        } else {
          onSelect({
            type: 'city',
            id: selectedItem.id,
            cityName: selectedItem.name,
            regionId: selectedItem.regions?.id,  // Pass region ID
            regionName: selectedItem.regions?.name || 'Unknown Region',
            countryId: selectedItem.regions?.countries?.id,  // Pass country ID
            countryName: selectedItem.regions?.countries?.name || 'Unknown Country',
          });
        }
      } else if (selectedItem.type === 'region') {
        if (!selectedItem.countries) {
          const regionData = await supabase
            .from('regions')
            .select('id, name, country_id, countries(id, name)')
            .eq('id', selectedItem.id)
            .single();
  
          onSelect({
            type: 'region',
            id: regionData.data.id,
            regionName: regionData.data.name,
            countryId: regionData.data.countries?.id,  // Pass country ID
            countryName: regionData.data.countries?.name || 'Unknown Country',
          });
        } else {
          onSelect({
            type: 'region',
            id: selectedItem.id,
            regionName: selectedItem.name,
            countryId: selectedItem.countries?.id,  // Pass country ID
            countryName: selectedItem.countries?.name || 'Unknown Country',
          });
        }
      } else if (selectedItem.type === 'country') {
        onSelect({
          type: 'country',
          id: selectedItem.id,
          countryName: selectedItem.name,
          regionName: null,
          cityName: null,
        });
      }
    }
  
    setSearchTerm(''); // Clear search after selection
    setSearchResults([]); // Clear results after selection
  };
  

  return (
    <div className="location-search">
      <TextField
        label="Search for a location..."
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={handleSearch}
      />
      {searchResults.length > 0 && (
        <ul className="search-results">
          {searchResults.map((result) => (
            <MenuItem
              key={`${result.type}-${result.id}`}
              onClick={() => handleSelect(result)}
            >
              <Typography variant="body1">{result.name}</Typography>
              <Typography variant="body2" color="textSecondary">
                {result.display.replace(result.name + ',', '')}
              </Typography>
            </MenuItem>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LocationSearch;
