import React, { useContext, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import SignUpPage from './pages/SignUpPage';
import LandingPage from './pages/LandingPage';
import NotFoundPage from './pages/NotFoundPage';
import ResetPassword from './components/ResetPassword';
import VerifyEmail from './components/VerifyEmail';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Imprint from './pages/Imprint';
import DataPrivacy from './pages/DataPrivacy';
import TermsOfService from './pages/TermsOfService';
import { AuthProvider, AuthContext } from './contexts/AuthContext';
import MyOrders from './pages/authenticated/MyOrders';
import MyOffers from './pages/authenticated/MyOffers';
import MyProfile from './pages/authenticated/MyProfile';
import MyAccount from './pages/authenticated/MyAccount';
import Profile from './pages/Profile';
import './App.css';
import i18n from './i18n'; // NEVER DELETE!

const App = () => {
  return (
    <AuthProvider>
      <Suspense fallback={<div>Loading...</div>}>
        <Router>
          <NavBar />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/imprint" element={<Imprint />} />
            <Route path="/data-privacy" element={<DataPrivacy />} />
            <Route path="/tos" element={<TermsOfService />} />
            <Route
              path="/signup"
              element={
                <RequireAuth redirectTo="/">
                  <SignUpPage key="signup" isSignUp={true} />
                </RequireAuth>
              }
            />
            <Route
              path="/login"
              element={
                <RequireAuth redirectTo="/">
                  <SignUpPage key="login" isSignUp={false} />
                </RequireAuth>
              }
            />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            {/* Protected Routes */}
            <Route
              path="/my-orders"
              element={
                <RequireAuth>
                  <MyOrders />
                </RequireAuth>
              }
            />
            <Route
              path="/my-offers"
              element={
                <RequireAuth>
                  <MyOffers />
                </RequireAuth>
              }
            />
            <Route
              path="/my-profile"
              element={
                <RequireAuth>
                  <MyProfile />
                </RequireAuth>
              }
            />
            <Route
              path="/my-account"
              element={
                <RequireAuth>
                  <MyAccount />
                </RequireAuth>
              }
            />
            <Route path="/hero/:username" element={<Profile />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          <Footer />
        </Router>
      </Suspense>
    </AuthProvider>
  );
};

const RequireAuth = ({ children }) => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  return user ? children : <SignUpPage key="login" isSignUp={false} redirectPath={location.pathname} />;
};

export default App;