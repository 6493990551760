export const loadScript = (src, id, callback) => {
  const existingScript = document.getElementById(id);
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = src;
    script.id = id;
    script.async = true;
    script.onload = callback;
    document.body.appendChild(script);
  } else {
    // Script already exists, directly call the callback
    callback();
  }
};